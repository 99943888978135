import { Module } from 'vuex'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { Nullable } from '@/common/custom/ts-customization'
import { AssemblyCalendarEvent, EventCell, EventTimespan } from '@/common/componentHelpers/AssemblyCalendar'
import { mapFieldsFunction } from '@/common/custom/vuex-map-fields-customization'
import { RootState } from './root.store'
import { AssemblyCalendarEntry } from '@/models/customview/AssemblyCalendar'
import { Configuration } from '@/models/order/Configuration'
import { AppDate } from '@/common/utils/AppDate'

type ExistingAssemblyData = {
  date: string
  span: EventTimespan
  wgid: string
  configID?: string
}

export type CalendarState = {

  /**
   * pomocna hodnota pre Order Detail/Edit na otvaranie montazneho kalendara ako picker
   * -1 => ziadny nieje otvoreny
   * 0-N => n-ty kalendar je povoleny na zobrazenie
   */
  calendarOpen: number

  /** rozlisenie, ci je picker-assembly-calendar v mode "zadat novy termin" alebo "upravit existujuci" */
  editingExistingAssembly: boolean

  editingWithoutPicker: boolean

  /** bonusove data k booleanu, ci je assembly picker v mode "upravit existujucu montaz" */
  existingAssemblyData?: ExistingAssemblyData

  /** listener set up in AssemblyCalendar root - to see if any dialog has to be invoked */
  eventCell: Nullable<EventCell>
  eventTimespan: EventTimespan

  // Dialog toggle and data

  dialogDetailOpen: boolean
  dialogDetailData: Nullable<AssemblyCalendarEvent>

  dialogEditOpen: boolean

  currentViewStartDate:Nullable<Date>
  dialogConflictsOpen: boolean
}


export const calendarModule: Module<CalendarState, RootState> = {
  namespaced: true,

  state: {
    calendarOpen: -1,
    editingExistingAssembly: false,
    editingWithoutPicker: false,
    existingAssemblyData: undefined,

    dialogDetailOpen: false,
    dialogDetailData: undefined,

    dialogEditOpen: false,

    eventCell: undefined,
    eventTimespan: 'none',
    currentViewStartDate: undefined,
    dialogConflictsOpen: false,
  },

  getters: { getField },
  mutations: { updateField },

  actions: {
    /** store.dispatch('calendar/reset') */
    reset(context) {
      context.commit('updateField', { path: 'dialogDetailOpen', value: false })
      context.commit('updateField', { path: 'dialogDetailData', value: undefined })
      context.commit('updateField', { path: 'dialogEditOpen', value: false })

      context.commit('updateField', { path: 'eventCell', value: undefined })
      context.commit('updateField', { path: 'eventTimespan', value: 'none' })
    },

    setEditedCellData(context, config: Configuration) {
      context.commit('updateField', { path: 'editingExistingAssembly', value: true })

      const value: ExistingAssemblyData = {
        date: AppDate.Pimcore.Format(config.installationDate),
        span: EventTimespan(config.installationMorning, config.installationAfternoon, config.installationEvening),
        wgid: config.workerGroup.workerGroupID,
        configID: config.id,
      }

      context.commit('updateField', {
        path: 'existingAssemblyData',
        value,
      })
    },

    openCalendarForEdit(context, { config, index }: { config: Configuration, index: number }) {
      context.dispatch('setEditedCellData', config)
      context.commit('updateField', { path: 'calendarOpen', value: index })
    },

    closeCalendar(context) {
      context.commit('updateField', { path: 'calendarOpen', value: -1 })
      context.commit('updateField', { path: 'editingExistingAssembly', value: false })
      context.commit('updateField', { path: 'existingAssemblyData', value: undefined })
      context.commit('updateField', { path: 'currentViewStartDate', value: undefined })
      context.commit('updateField', { path: 'dialogConflictsOpen', value: false })
    },

    openDetailDialog(context, payload: AssemblyCalendarEntry) {
      context.commit('updateField', { path: 'dialogDetailData', value: payload })
      context.commit('updateField', { path: 'dialogDetailOpen', value: true })
    },

    openEditDialog(context) {
      context.commit('updateField', { path: 'dialogEditOpen', value: true })
    },

    rememberDate(context, date: Date) {
      context.commit('updateField', { path: 'rememberDate', value: date })
    },
  },

}


export const mapFieldsCalendar = createHelpers({
  getterType: 'calendar/getField',
  mutationType: 'calendar/updateField',
}).mapFields as mapFieldsFunction<CalendarState>
