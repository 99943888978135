import { GQLWorker } from '@/api/graphql/app/worker/GQLWorker'
import { GQLWorkerGroup } from '@/api/graphql/app/workerGroup/GQLWorkerGroup'
import { GQLWorkerOccupancy } from '@/api/graphql/app/workerOccupancy/GQLWorkerOccupancy'
import { WorkersAPI } from '@/api/interfaces/database/workers.interface'


export const IWorkersAPI: WorkersAPI = {

  groupList: GQLWorkerGroup.List.execute,
  groupRead: GQLWorkerGroup.Read.execute,
  groupCreate: GQLWorkerGroup.Create.procedure,
  groupUpdate: GQLWorkerGroup.Update.procedure,

  workerCreate: GQLWorker.Create.procedure,
  workerUpdate: GQLWorker.Update.procedure,
  workerDelete: GQLWorker.Delete.procedure,

  workerOccupacyCreate: GQLWorkerOccupancy.Create.procedure,
  workerOccupancyGet: GQLWorkerOccupancy.Get.execute,
  workerOccupancyDelete: GQLWorkerOccupancy.Delete.procedure,
  workerOccupancyUpdate: GQLWorkerOccupancy.Update.procedure,

}
