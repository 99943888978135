import {
  Maybe,
  Object_Worker,
  Object_WorkerGroup,
  UpdateWorkerGroupInput,
} from '@/api/graphql/schema/app.graphql.types'
import { get, ObjectParams } from '@/common/custom/ts-customization'
import { PimcoreApiUser } from '../pimcore/PimcoreApiUser'

import { PimcoreNode } from '../pimcore/PimcoreNode'
import { PimcoreRelation } from '../pimcore/PimcoreRelation'
import { Worker } from './Worker'
import { BranchOffice } from '@/models/branchoffice/BranchOffice'

export type WorkerGroup = PimcoreNode & {
  workerGroupID: string
  availableMorning: boolean
  availableAfternoon: boolean
  availableEvening: boolean
  color: string

  certificate: string
  address: string
  ratePerKm: number
  commission: number

  leadingWorker: Worker
  workers: Worker[]

  apiUser?: PimcoreApiUser
  groupIndex: number
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
  branchOffices: BranchOffice[]
}

export const WorkerGroup = (o?: Maybe<Object_WorkerGroup>): WorkerGroup => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  workerGroupID: o?.workerGroupID || '',
  availableMorning: o?.availableMorning || false,
  availableAfternoon: o?.availableAfternoon || false,
  availableEvening: o?.availableEvening || false,
  color: o?.color || '#cccccc',

  certificate: o?.certificate || '',
  address: o?.address || '',
  ratePerKm: o?.ratePerKm || 0,
  commission: o?.commission || 0,

  leadingWorker: Worker(o?.leadingWorker),
  workers: get<Maybe<Object_Worker>[]>(o?.workers, []).map(w => Worker(w)),

  apiUser: o?.apiUser ? PimcoreApiUser(o.apiUser) : undefined,
  groupIndex: o?.groupIndex || calculateGroupIndex(o?.workerGroupID),
  monday: o?.monday || false,
  tuesday: o?.tuesday || false,
  wednesday: o?.wednesday || false,
  thursday: o?.thursday || false,
  friday: o?.friday || false,
  saturday: o?.saturday || false,
  sunday: o?.sunday || false,
  branchOffices: JSON.parse(o?.branchOffices || '[]').map((w) => BranchOffice(w)),
})

export const WorkerGroupExport = (o: WorkerGroup): UpdateWorkerGroupInput => {
  const exported: UpdateWorkerGroupInput = {}
  for (const k of ObjectParams(o)) {
    switch (k) {
      case 'leadingWorker': exported[k] = PimcoreRelation('object', o.leadingWorker); break
      case 'workers':       exported[k] = o.workers.map(i => PimcoreRelation('object', i)!); break
      case 'apiUser':       exported[k] = PimcoreRelation('object', o.apiUser); break
      case 'branchOffices': break // skip
      default: (exported[k] as any) = o[k]
    }
  }
  return exported
}


/** This is so disgusting. But logical AF */
export const calculateGroupIndex = (wgid?: Maybe<string>) => {
  const rnd = Math.round(Math.random() * 100)
  if (!wgid) return 999999 + rnd
  const d3 = wgid.toUpperCase().charCodeAt(0) || 0
  const d2 = wgid.toUpperCase().charCodeAt(1) || 0
  const d1 = wgid.toUpperCase().charCodeAt(2) || 0
  return d3 * 10000 + d2 * 100 + d1
}
