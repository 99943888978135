import { useStorage } from '@vueuse/core'
import { Nullable } from '@/common/custom/ts-customization'
import { computed } from 'vue'

export interface AssemblyCalendarState {
  date: Nullable<Date>
}

const initialState: AssemblyCalendarState = {
  date: undefined,
}

export const useAssemblyCalendarRememberState = () => {
  const $state = useStorage<AssemblyCalendarState>('assemblyCalendarRememberState', { ...initialState }, localStorage, { mergeDefaults: true })

  const state = computed(() => $state.value)

  const setRememberDate = (date: Date) => {
    $state.value.date = date
  }

  const clear = () => {
    $state.value = { ...initialState }
  }

  return {
    state,
    setRememberDate,
    clear,
  }
}
