import { AuthAPI } from '@/api/interfaces/auth.api.interface'
import { endpoints } from '@/common/constants/endpoints'
import { CreateUser, isJWT, JWT, User } from '@/common/logic/auth/auth.types'
import { AuthToken } from '@/common/logic/auth/tokens'
import { APIGet, APIPost, isAPIError } from '@/common/utils/REST'
import { PimcoreUserResponse } from '@/models/pimcore/restapi/PimcoreUserResponse'
import { AuthState } from '@/store/auth.store'
import { storeAction } from '@/store/store.actions'
import { getState } from '@/store/store.getters'
import { clog } from '@/common/utils/AppConsole'
import { useAssemblyCalendarRememberState } from '@/composables/useAssemblyCalendarRememberState'

export const IAuthAPI: AuthAPI = {

  async initialize() {
    const state = getState.auth()
    const isAuthenticated = state.expires > 0 && state.user.email
    if (isAuthenticated) {
      if (AuthToken.isExpired(state.expires)) {
        if (await IAuthAPI.userdata()) {
          clog('[initialize] - expired token was refreshed')
        }
      }
      else {
        const remaining = AuthToken.getRemainingTime(state.expires)
        clog(`[initialize] token remaining milliseconds ${remaining}`)
        setTimeout(IAuthAPI.userdata, remaining)
      }
    }

    (window as any).isExpired = AuthToken.isExpired
    ;(window as any).expires = () => getState.auth().expires
  },

  async userdata(credentials) {
    const state = getState.auth()

    // fetch auth state data - jwt, user data, generate new expiration token
    const jwt = credentials
      ? await APIPost<JWT>(endpoints.auth.login, { username: credentials.mail, password: credentials.pass })
      : ''

    if (!isJWT(jwt)) return false

    const pimUser = jwt ? await APIGet<PimcoreUserResponse>(endpoints.auth.user, jwt.token) : undefined

    if (isAPIError(pimUser)) return false

    // if both REST API actions were successful, update store and set expiration
    if (jwt && pimUser) {
      // token must be stored prior to user branches query which requires a valid token
      await storeAction.auth.setState({ ...state, jwt })
      const user = await CreateUser(pimUser)
      const expires = AuthToken.getExpirationTimestamp()
      await storeAction.auth.setState({ ...state, jwt, user, expires })

      setTimeout(IAuthAPI.userdata, AuthToken.getRemainingTime(expires))
    }
    else {
      await storeAction.auth.setState({ ...state, jwt: JWT(), user: User(), expires: 0 })
    }

    return !!jwt && !!pimUser
  },

  async login(credentials) {
    const authState = getState.auth()
    const r = await this.userdata(credentials)
    await storeAction.auth.setState({ ...authState, flag: r ? 'AUTH' : 'ERROR' })
    return r
  },

  async logout() {
    useAssemblyCalendarRememberState().clear()

    await storeAction.auth.setState(AuthState('DEFAULT'))
    return true
  },


  async forgottenPassword(email) {
    const payload = { email }
    const r = await APIPost<PimcoreUserResponse>(endpoints.auth.password_reset, payload)
    return !!r || false
  },


  async finishPasswordReset(email, password, passwordRepeat, passwordResetHash) {
    const payload = { email, password, passwordRepeat, passwordResetHash }

    const passwordChangeResponse = await APIPost<PimcoreUserResponse>(endpoints.auth.password_change, payload)

    if (passwordChangeResponse) {
      await this.login({
        mail: email,
        pass: password,
      })
    }

    return !!passwordChangeResponse
  },


  async changePassword(email, passwordOld, passwordNew) {
    const payload = { email, passwordOld, passwordNew }
    const authState = getState.auth()
    const r = await APIPost<PimcoreUserResponse>(endpoints.auth.password_update, payload, authState.jwt.token)

    if (isAPIError(r)) return false

    if (r && r.token && r.refresh_token) {
      const user = await CreateUser(r)
      await storeAction.auth.setState({
        flag: 'AUTH',
        user,
        jwt: JWT(r.token, r.refresh_token),
        expires: AuthToken.getExpirationTimestamp(),
      })
    }

    return !!r
  },

}
