import gql from 'graphql-tag'

export default gql`
  query ListBranchOffices($applicationMode: [String], $workerGroupId: Int) {
    getBranchOfficeListing(
      applicationMode: $applicationMode
      workerGroupId: $workerGroupId
    ) {
      totalCount
      edges {
        node {
          id
          key
          fullpath
          name
          applicationMode
          code
          address
          city
          phone
          email
          commission
          expedition
          businessRegisterName
          billingCity
          billingPostalCode
          companyIdNumber
          companyTaxNumber
          billingRegisteredIn
          url
          isWholesale
          isArchived
          stampAsset {
            ... on asset {
              id
              fullpath
              filename
              filesize
              type
              mimetype
            }
          }
          branchType
          parentName
          employees {
            ... on fieldcollection_emloyee {
              name
              position
              phone
              email
              photo {
                ... on hotspotimage {
                  image {
                    ... on asset {
                      id
                      fullpath
                      filename
                      filesize
                      type
                      mimetype
                    }
                  }
                }
              }
            }
          }

          location {
            ... on Geopoint {
              longitude
              longitude
            }
          }

          holidayFrom
          holidayTill

          workerGroups {
            ... on object_WorkerGroup {
              id
              key
              fullpath
              workerGroupID
              availableMorning
              availableAfternoon
              availableEvening
              certificate

              leadingWorker {
                ... on object_Worker {
                  id
                  key
                  fullpath
                  firstName
                  lastName
                  phone
                  email
                }
              }

              workers {
                ... on object_Worker {
                  id
                  key
                  fullpath
                  firstName
                  lastName
                  phone
                  email
                }
              }
            }
          }

          apiUsers {
            ... on object_ApiUser {
              id
              key
              fullpath
              name
              email
              passwordResetHash
            }
          }
        }
      }
    }
  }
`
