import { rlog, ulog } from '@/common/utils/AppConsole'
import { PimcoreNodeImport, PimcoreNodeStrip } from '@/models/pimcore/PimcoreNode'
import { WorkerGroup, WorkerGroupExport } from '@/models/workers/WorkerGroup'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { CreateWorkerGroupResult, Object_WorkerGroup, UpdateWorkerGroupInput, UpdateWorkerGroupResult, WorkerGroupConnection } from '../../schema/app.graphql.types'
import createWorkerGroup from './createWorkerGroup'
import getWorkerGroup from './getWorkerGroup'
import getWorkerGroupListing from './getWorkerGroupListing'
import updateWorkerGroup from './updateWorkerGroup'

export namespace GQLWorkerGroup {

  export namespace List {

    const query = getWorkerGroupListing

    export type Data = {
      getWorkerGroupListing: WorkerGroupConnection
    }

    export const execute = async () => appClient
      .query<Data>({ query, fetchPolicy: 'no-cache' })
      .then(r => r.data.getWorkerGroupListing.edges != null
        ? r.data.getWorkerGroupListing.edges.map(item => WorkerGroup(item?.node))
        : rlog<WorkerGroup[]>('e', [], 'GQLWorkerGroup(L) failed', r)
      )
      .catch(e => rlog<WorkerGroup[]>('e', [], 'GQLWorkerGroup(L) exception', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Read {

    const query = getWorkerGroup

    export type Variables = {
      id: number
    }

    export type Data = {
      getWorkerGroup: Object_WorkerGroup
    }

    export const execute = async (variables: Variables) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getWorkerGroup
        ? WorkerGroup(r.data.getWorkerGroup)
        : ulog('e', 'GQLWorkerGroup(R) failed', r)
      )
      .catch(e => ulog('e', 'GQLWorkerGroup(R) exception', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Create {

    const mutation = createWorkerGroup

    export type Variables = {
      key: string
      parentId: number
      values: UpdateWorkerGroupInput
    }

    export type Data = {
      createWorkerGroup: CreateWorkerGroupResult
    }

    const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.createWorkerGroup.success && r.data.createWorkerGroup.output
        ? PimcoreNodeImport(r.data.createWorkerGroup.output)
        : ulog('e', 'GQLWorkerGroup(C) failed', r)
      )
      .catch(e => ulog('e', 'GQLWorkerGroup(C) exception', e))

    export const procedure = async (group: WorkerGroup) =>
      execute({ key: `${group.workerGroupID} ${(Math.random() + 1).toString(36).substring(7)}`, parentId: 24, values: WorkerGroupExport(group) })

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {

    const mutation = updateWorkerGroup

    export type Variables = {
      fullpath: string,
      values: UpdateWorkerGroupInput
    }

    export type Data = {
      updateWorkerGroup: UpdateWorkerGroupResult
    }

    const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.updateWorkerGroup.success && r.data.updateWorkerGroup.output
        ? PimcoreNodeImport(r.data.updateWorkerGroup.output)
        : ulog('e', 'GQLWorkerGroup(U) failed', r)
      )
      .catch(e => ulog('e', 'GQLWorkerGroup(U) exception', e))

    export const procedure = async (group: WorkerGroup) =>
      execute({
        fullpath: group.fullpath!,
        values: WorkerGroupExport(PimcoreNodeStrip(group)),
      })

  }
}
