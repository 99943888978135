import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { StateConfig } from '@/models/order/workflow/States'
import { ApplyConfigurationActionResult, ConfigurationActionsListType } from '../schema/app.graphql.types'
import { Configuration } from '@/models/order/Configuration'
import { rlog } from '@/common/utils/AppConsole'
import { PimcoreActionListImport, PimcoreConfigAction } from '@/models/order/workflow/Actions'


export namespace GQLConfigActions {

  export type Error = 'NOTENABLED' | 'UNKNOWN'
  export const isError = (o: StateConfig | Error): o is Error => o === 'NOTENABLED' || o === 'UNKNOWN'

  export namespace Get {

    const query = gql`
      query GetConfigurationActions($configurationId: Int!) {
        getConfigurationActions(configurationId: $configurationId) {
          actions
        }
      }
    `

    export type Variables = {
      configurationId: number
    }

    export type Data = {
      getConfigurationActions?: ConfigurationActionsListType
    }

    export const execute = (variables: Variables) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getConfigurationActions && r.data.getConfigurationActions.actions
        ? PimcoreActionListImport<PimcoreConfigAction>(r.data.getConfigurationActions.actions)
        : rlog<PimcoreConfigAction[]>('e', [], 'GQLConfigurationActions(Get) failed', r)
      )
      .catch(e => rlog<PimcoreConfigAction[]>('e', [], 'GQLConfigurationActions(Get) exception', e))

    export const procedure = async (config: Configuration) => config.id ? execute({ configurationId: parseInt(config.id) }) : <PimcoreConfigAction[]>[]

  }


  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


  export namespace Apply {

    const mutation = gql`
      mutation ApplyConfigurationAction($configurationId: Int!, $action: String!, $frameId: Int) {
        applyConfigurationAction(configurationId: $configurationId, action: $action, frameId: $frameId) {
          success
          message
          output
        }
      }
    `

    export type Variables = {
      configurationId: number
      action: string
      frameId: number | null
    }

    export type Data = {
      applyConfigurationAction: ApplyConfigurationActionResult
    }

    export const execute = (variables: Variables): Promise<StateConfig | Error> => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.applyConfigurationAction.success && !!r.data.applyConfigurationAction.output
        ? r.data.applyConfigurationAction.output as StateConfig
        : rlog<Error>('e', 'UNKNOWN', 'GQLConfigActions(Apply) failed', r)
      )
      .catch(e => rlog<Error>('e', 'UNKNOWN', 'GQLConfigActions(Apply) exception', e))

    export const procedure = async (config: Configuration, action: PimcoreConfigAction, frameId: number|null = null): Promise<StateConfig | Error>  => {
      if (!config.id) return ''
      const availableActions = await Get.procedure(config)
      return availableActions.includes(action)
        ? execute({ configurationId: parseInt(config.id), action, frameId })
        : <Error>'NOTENABLED'
    }


  }

}
